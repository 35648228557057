import { MutableRefObject } from 'react';
import type { TExperiment } from '@printdeal/feature-management/react';
import { scrollIntoView } from '@printdeal/ui-library';
import type { WindowLocation } from '@gatsbyjs/reach-router';
import type { SegmentationData } from '@printdeal/segmentation/react';
import type { MarketingBannerExperimentData, HomePageBannerData } from '../../components/homepage/homepage-banner/HomepageBanner';
import {
  getFromLocalStorage, LS_KEYS, windowPresent, parseStringifiedValue, setInLocalStorage,
} from '../../helpers/window';

export interface AudiencesData {
  type: string | null;
}

export interface AudienceOverRide {
  isNull: boolean;
  queryKey: string | null;
}

export const SUPPORTED_AUDIENCE_TYPES = ['web_retail_industry', 'web_catering_industry'];

export const scrollToRef = (target: MutableRefObject<HTMLDivElement | null>) => {
  if (!target?.current) return;

  scrollIntoView(target.current, {
    block: 'start',
    behavior: 'smooth',
  });
};

export const getBannerDataForVariations = (
  { variation }: TExperiment,
  { control, variant }: MarketingBannerExperimentData,
  isInitialized: Boolean,
) => {
  if (variation === 'variant' && isInitialized) return variant;
  return control;
};

export const getBannerDataForAudience = (
  audienceType: string | null | undefined,
  allMarketingBanners: HomePageBannerData[],
) => allMarketingBanners.find((bannerData) => bannerData.audienceType === audienceType);

export const isRecognizedUser = (accountId: string): boolean => {
  let ajsUserId: string | null = parseStringifiedValue(getFromLocalStorage(LS_KEYS.AJS_USER_ID));
  ajsUserId = ajsUserId === 'null' ? null : ajsUserId;
  const userId = ajsUserId || accountId;
  return !!userId;
};

/**
 *
 * @param location location from gatsby reach router
 * @param queryKey the exact query parameter, we are looking for
 * @returns an object with queryKey and it's value and whether the query key is valid
 */
export const getQueryParamFromLocation = (location: WindowLocation, queryKey: string) => {
  if (windowPresent) {
    const searchParams = new URLSearchParams(location.search);
    return {
      queryKey: searchParams.get(queryKey),
      isNull: !searchParams.get(queryKey),
    };
  }
  return {
    queryKey: '',
    isNull: true, // No valid query params present
  };
};

/**
 * Moved this method from useMarketingBanner() hook to helper,
 * which decouples audience data from banner hook
 * @param customerTraits traits data which contains audiences
 * @param audienceLoadedFromSegment isSuccess from useSegmentation() hook
 * @param audienceOverRide over-ride params from url for playground
 * @returns current audience type
 */

export const getAudiences = (
  customerTraits: SegmentationData | undefined,
  audienceLoadedFromSegment: boolean,
  audienceOverRideForPlayground: AudienceOverRide,
) => {
  const audienceOverRidden = !audienceOverRideForPlayground?.isNull;
  /**
   * if audience override is present in the url location, ignore all other cases
   * & set the audience type from url location query parameters
   */
  if (audienceOverRidden) {
    return {
      type: audienceOverRideForPlayground?.queryKey,
    };
  }

  if (!audienceOverRidden && audienceLoadedFromSegment) {
    const filteredAudiences = customerTraits?.audiences
      .filter((audience) => SUPPORTED_AUDIENCE_TYPES.includes(audience))?.[0] || 'no_audience';
    /**
     * If the user is recognized, we need to store the audience type as well in LS
     */
    setInLocalStorage(LS_KEYS.AUDIENCE_TYPE, filteredAudiences);
    return {
      type: filteredAudiences,
    };
  }
  // Traits with no audience, display default content.
  return {
    type: 'no_audience',
  };
};
