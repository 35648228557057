import React, { useMemo } from 'react';
import {
  FeatureManagementProvider as FeatureManagementProviderComponent,
} from '@printdeal/feature-management/react';
import { useSelector } from 'react-redux';
import { useBreakpoint } from '@printdeal/ui-library';
import { useIntl } from 'react-intl';
import { useSegmentation } from '@printdeal/segmentation/react';
import { windowPresent } from '../../helpers/window';
import { getStore } from '../../helpers/env';
import { getConfiguration } from '../../helpers/Configuration';
import { isRecognizedUser } from '../../templates/homepage/homepage-helper';

const getTier = () => {
  if (windowPresent) {
    return window.location.hostname === getConfiguration()?.channel?.url ? 'production' : 'development';
  }

  return 'development';
};

interface Props {
  children: React.ReactNode;
}

const FeatureManagementProvider = ({ children }: Props) => {
  const { locale } = useIntl();
  const { accountEmail, isCompany, accountId } = useSelector<{
    customer: {
      account: { id: string, email: string },
      customer: { custom?: { fields?: { isCompany?: string } } }
    },
  }, {
    accountId?: string,
    accountEmail?: string,
    isCompany?: string,
  }>(({ customer }) => ({
    accountId: customer.account?.id || '',
    accountEmail: customer.account?.email,
    isCompany: customer.customer?.custom?.fields?.isCompany,
  }));
  const isDesktop = useBreakpoint('lg');
  const segmenation = useSegmentation();

  const user = useMemo(() => {
    const customerSegmentAttributes = Object.create(null);

    if (segmenation.traits) {
      if (segmenation.traits.traits.customerSegmentLevel0) {
        customerSegmentAttributes.customerSegmentLevel0 = segmenation.traits.traits.customerSegmentLevel0;
      }
      if (segmenation.traits.traits.customerSegmentLevel1) {
        customerSegmentAttributes.customerSegmentLevel1 = segmenation.traits.traits.customerSegmentLevel1;
      }
      if (segmenation.traits.traits.customerSegmentLevel2) {
        customerSegmentAttributes.customerSegmentLevel2 = segmenation.traits.traits.customerSegmentLevel2;
      }
    }

    return ({
      email: accountEmail || undefined,
      store: getStore(),
      locale,
      custom: {
        isRecognized: isRecognizedUser(accountId || ''),
        device: isDesktop ? 'desktop' : 'mobile',
        isLoggedIn: Boolean(accountEmail),
        isCompany: Boolean(isCompany),
        audiences: segmenation.traits?.audiences,
        ...customerSegmentAttributes,
      },
    });
  }, [locale, accountEmail, isCompany, isDesktop, segmenation.traits, accountId]);

  if (!process.env.GATSBY_STATSIG_CLIENT_KEY) {
    throw new Error('Environment variable "GATSBY_STATSIG_CLIENT_KEY" is not set.');
  }

  return (
    <FeatureManagementProviderComponent
      user={user}
      apiKey={process.env.GATSBY_STATSIG_CLIENT_KEY}
      environment={getTier()}
    >
      {children}
    </FeatureManagementProviderComponent>
  );
};

export default FeatureManagementProvider;
