import axios from 'axios';
import { jwtDecode, type JwtPayload } from 'jwt-decode';
import { authenticationHttpClient } from '@printdeal/authentication';
import {
  windowPresent, getFromLocalStorage, LS_KEYS, setInLocalStorage,
} from './window';
import { TokenExpiredError } from '../errors/TokenExpiredError';

export interface AnonymousCoamIdentity {
  anonymousToken: string
  anonymousId: string
  validationKey: string
}

export interface PrintdealAuthTokenPayload extends JwtPayload {
  anonymous?: boolean;
  account?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  customer?: {
    id: string;
  };
  storeKey: string;
}

const decodeJTW = (token: string) => jwtDecode<JwtPayload>(token);

const persistAuthToken = (token: string) => setInLocalStorage(LS_KEYS.AUTH_TOKEN, token);
const persistCOAMToken = (token: string) => setInLocalStorage(LS_KEYS.ANON_COAM_IDENTITY, token);
const checkAuthToken = (): string | undefined => getFromLocalStorage(LS_KEYS.AUTH_TOKEN);
const checkCOAMToken = (): string | undefined => getFromLocalStorage(LS_KEYS.ANON_COAM_IDENTITY);

/**
 * Get an anonymous Printdeal token
 */
const getAnonymousToken = async (): Promise<string> => {
  // Somehow Gatsby tries to fetch a token during build time,
  // which will result in an error, causing every incremental
  // build to rebuild all HTML pages instead of only the
  // updated ones. This line prevents that.
  if (!windowPresent) return '';

  // Perform this call via the authenticationHttpClient, which de-dupes
  // createToken calls in case multiple calls are attempted in parallel.
  const {
    data: { token },
  } = await authenticationHttpClient.createToken();

  return token;
};

/**
 * Get an anonymous COAM identity
 * @see https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/537165901/Anonymous+Authentication
 */
const getAnonymousCOAMIdentity = async (
  identity?: AnonymousCoamIdentity,
): Promise<AnonymousCoamIdentity | undefined> => {
  // Somehow Gatsby tries to fetch a token during build time,
  // which will result in an error, causing every incremental
  // build to rebuild all HTML pages instead of only the
  // updated ones. This line prevents that.
  if (!windowPresent) return undefined;

  const { data } = await axios.post<AnonymousCoamIdentity>(
    `${process.env.GATSBY_AUTH_LAYER_ENDPOINT}/anon-coam-identity/`,
    identity,
  );

  return data;
};

/**
 * Check if token is expired
 */
const isDecodedTokenExpired = (decodedToken: PrintdealAuthTokenPayload | JwtPayload): boolean => {
  if (!decodedToken.exp) {
    return true;
  }

  // If the expiry time is before the current time, it's expired
  return Boolean(decodedToken.exp <= Math.floor(new Date().getTime() / 1000));
};

const decodePrintdealToken = (token: string): PrintdealAuthTokenPayload => jwtDecode<PrintdealAuthTokenPayload>(token);

const getDecodedToken = (): PrintdealAuthTokenPayload | undefined => {
  const token = checkAuthToken();
  if (token) {
    return decodePrintdealToken(token);
  }
  return undefined;
};

/**
 * Check if user is authenticated
 */
const isAuthenticated = (token = checkAuthToken()): boolean => {
  if (!token) {
    return false;
  }

  const decodedToken = decodePrintdealToken(token);

  if (!decodedToken) {
    return false;
  }

  if ('anonymous' in decodedToken || isDecodedTokenExpired(decodedToken)) {
    return false;
  }

  return true;
};

/**
 * If a customers login token is expired but he makes a request that is allowed for non logged in users
 * it creates automatically a new anonymousToken
 * This behavior can be disabled by setting `blockAnonymous` to `true` in the request config
 * @returns {string}
 */
const getAuthToken = async (allowAnonymous: boolean): Promise<string | undefined> => {
  const token = getDecodedToken();

  if (token && (isDecodedTokenExpired(token) || !token.storeKey)) {
    if (!allowAnonymous) {
      throw new TokenExpiredError('Token expired; anonymous fallback not allowed');
    }

    const anonymousToken = await getAnonymousToken();
    persistAuthToken(anonymousToken);
    return anonymousToken;
  }
  return checkAuthToken();
};

const getCOAMToken = async (): Promise<AnonymousCoamIdentity | undefined> => {
  const COAMToken = checkCOAMToken();

  if (!COAMToken) {
    const anonymousToken = await getAnonymousCOAMIdentity();
    persistCOAMToken(JSON.stringify(anonymousToken));
    return anonymousToken;
  }

  const token = COAMToken && jwtDecode(COAMToken) as JwtPayload;

  if (token && COAMToken && isDecodedTokenExpired(token)) {
    const identity = JSON.parse(COAMToken) as AnonymousCoamIdentity;
    const anonymousToken = await getAnonymousCOAMIdentity(identity);
    persistCOAMToken(JSON.stringify(anonymousToken));
    return anonymousToken;
  }

  if (COAMToken) {
    return JSON.parse(COAMToken) as AnonymousCoamIdentity;
  }

  return undefined;
};

export {
  persistAuthToken,
  getDecodedToken,
  isDecodedTokenExpired,
  isAuthenticated,
  getAuthToken,
  getAnonymousCOAMIdentity,
  getAnonymousToken,
  decodeJTW,
  decodePrintdealToken,
  getCOAMToken,
};
