import Api from '../Api';
import Order from '../../types/order/Order';

interface GetPaymentMethodsResponse {
  default: string, // e.g. 'ideal'
  methods: Array<{
    costs: {
      centAmount: string, // e.g. '0'
      currency: string, // e.g. 'EUR'
    },
    id: string, // e.g. 'ideal'
    name: string, // e.g. 'iDEAL'
    provider: string, // e.g. 'PSP-ADYEN', 'PSP-Unit4'
    mandates?: Array<{
      active: boolean,
      bicNumber: unknown,
      city: unknown,
      customerId: number,
      ibanNumber: unknown,
      id: string,
      legacyId: unknown
      mandateIdentification: unknown,
      maxAmount: unknown,
      name: unknown,
      portalId: number,
      requested_date: string,
      signatureDate: unknown,
      statusId: string,
      usable: boolean,
    }>,
  }>,
}

/**
 * Response is payment link (without JSON), e.g. 'https://test.adyen.com/'
 */
type GetPaymentInformationResponse = string | undefined;

export type Mandate = {
  mandateId: string,
  status: string,
  signerName?: string,
  bankId?: string,
  iban?: string,
};

export type Issuer = {
  id: string;
  name: string;
  country: string;
};

class PaymentV2Service extends Api {
  async getPaymentMethodsForOrder(
    orderId: string,
    psp: string = 'buckaroo',
  ): Promise<GetPaymentMethodsResponse> {
    const { data } = await this.httpClient.get<GetPaymentMethodsResponse>('/api/payments/methods', {
      blockAnonymous: true,
      params: {
        orderId,
        psp,
      },
    });

    return data;
  }

  async getPaymentInformationForOrder(
    orderNumber: string | undefined,
    locale: string,
    psp: string = 'buckaroo',
    customReturnPath?: string,
  ): Promise<GetPaymentInformationResponse> {
    if (!orderNumber) {
      return undefined;
    }

    // eslint-disable-next-line max-len
    const { data } = await this.httpClient.get<GetPaymentInformationResponse>(`/api/payments/links/orders/${orderNumber}`, {
      params: {
        locale,
        psp,
        customReturnPath,
      },
    });

    return data;
  }

  async getPaymentInformationForInvoice(
    invoiceNumber: string,
    locale: string,
    psp: string = 'buckaroo',
  ): Promise<GetPaymentInformationResponse> {
    // eslint-disable-next-line max-len
    const { data } = await this.httpClient.get<GetPaymentInformationResponse>(`/api/payments/links/invoices/${invoiceNumber}`, {
      params: {
        locale,
        psp,
      },
    });

    return data;
  }

  async changePaymentMethodOfOrder(
    paymentMethod: string,
    orderId: string,
  ): Promise<Order> {
    const { data } = await this.httpClient.post<Order>(
      '/api/payments/methods/change', { orderId, paymentMethod },
    );

    return data;
  }

  async getMandates(): Promise<Mandate[]> {
    const { data } = await this.httpClient.get<Mandate[]>('/api/mandates');

    return data;
  }

  async getMandate(mandateId: string): Promise<Mandate> {
    const { data } = await this.httpClient.get<Mandate>(`/api/mandates/${mandateId}`, { blockAnonymous: true });

    return data;
  }

  async getMandateIssuers(): Promise<Issuer[]> {
    const { data } = await this.httpClient.get<Issuer[]>('/api/mandates/issuers');

    return data;
  }

  async createMandate(
    issuerId: string,
  ): Promise<string> {
    const { data } = await this.httpClient.post<string>('/api/mandates', { issuerId });

    return data;
  }

  async cancelMandate(
    mandateId: string,
  ): Promise<string> {
    const { data } = await this.httpClient.delete<string>(`/api/mandates/${mandateId}`);

    return data;
  }
}

export default PaymentV2Service;
