import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomerCareComponent } from '@printdeal/configuration';
import { Collapsible } from '@printdeal/ui-library';
import NextStepsForUserAfterError from './NextStepsForUserAfterError';

/**
 * This is a simplified version of the one in ./ErrorAlert.tsx, to be injected as reusable solution for microfrontends.
 * Eventually this can all move to the shared utilities repo and the more complicated launchpad version
 * can at some point be phased out.
 * For now, this approach was chosen to not blow up the amount of effort in moving code around, writing new
 * translations, etc.
 */
const CustomerCareDropdown: CustomerCareComponent = ({ showDebugId }) => (
  <Collapsible id="customer-care-dropdown-on-error" className="pd-text-sm">
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <Collapsible.Header
      title={(
        <FormattedMessage
          id="general.errorHandling.contactIfProblemPersists"
          defaultMessage="Please contact us if the problem persists"
        />
      )}
      className="pd-p-3 pd-text-left pd-text-md"
    />
    <Collapsible.Panel className="pd-p-3">
      <NextStepsForUserAfterError showDebugId={showDebugId} />
    </Collapsible.Panel>
  </Collapsible>
);

export default CustomerCareDropdown;
