import { getStates } from '@printdeal/business-logic';
import { Address } from '@printdeal/address-frontend/refactor';
import Api from '../Api';
import { OrderEditResponse } from '../../types/order/OrderEdit';
import { CounterResponse, GetExtendedOrderResponse, GetOrdersResponse } from '../../types/order/Order';

const ENV = process.env.GATSBY_ACTIVE_ENV === 'production' ? 'prod' : 'staging';

export interface GetOrdersParams {
  ordersPerPage: number;
  pageIndex?: number | null;
  filter?: string | null;
  search?: string | null;
}

export interface EditOrderLineItemRequest {
  changedAttributes: { [attributeName: string]: string };
  quantity: number;
  removeFile: boolean;
}

class OrderService extends Api {
  hasOrders = async () => {
    const params = new URLSearchParams();
    params.set('limit', '1');
    params.set('offset', '0');
    const { data: { message: orders } } = await this.httpClient.get(
      `order/getMyOrders?${params}`,
      { blockAnonymous: true },
    );
    return Promise.resolve(Boolean(orders?.total > 0));
  };

  setPoNumber = async (poNumber: string, orderId: string) => {
    const {
      data: { message },
    } = await this.httpClient.post('/order/setPONumber', { poNumber, orderId });
    return Promise.resolve(message);
  };

  setReference = async (reference: string, orderId: string) => {
    const {
      data: { message },
    } = await this.httpClient.post('/order/setReference', { reference, orderId });
    return Promise.resolve(message);
  };

  setBillingAddress = async (orderId: string, address: Address) => {
    const {
      data: { message },
    } = await this.httpClient.post(`/order/setBillingAddress?id=${orderId}`, { address });
    return Promise.resolve(message);
  };

  setShippingAddress = async (orderId: string, lineItems: string[], address: Address) => {
    const {
      data: { message },
    } = await this.httpClient.post(
      `/order/setShippingAddress?id=${orderId}`,
      { lineItems, address },
      { headers: { 'Accept-version': 'v2' } },
    );
    return Promise.resolve(message);
  };

  getOrder = async (orderId: string) => {
    const {
      data: { message },
    } = await this.httpClient.get<GetExtendedOrderResponse>(
      `/order/getMyOrder?id=${orderId}`,
      { blockAnonymous: true },
    );
    return Promise.resolve(message);
  };

  getOrderByOrderNumber = async (orderNumber: string) => {
    const {
      data: { message },
    } = await this.httpClient.get(`/order/getMyOrder?orderNumber=${orderNumber}`);
    return Promise.resolve(message);
  };

  getOrders = async ({
    filter, ordersPerPage, pageIndex, search,
  }: GetOrdersParams) => {
    const {
      lineItem, order,
    } = getStates(ENV);
    let lineItemStates: string[] = [];
    let orderStates: string[] = [];
    const params = new URLSearchParams();

    if (filter) {
      switch (filter) {
        case 'paymentNeeded':
          orderStates = [order.OPEN, lineItem.PROCESSING];
          break;
        case 'needsFileDelivery':
          lineItemStates = [lineItem.WAITING_FOR_ARTWORK, lineItem.DOC_REVIEW_REJECTED];
          break;
        case 'inProduction':
          lineItemStates = [
            lineItem.READY_FOR_FULFILLMENT,
            lineItem.SENT_TO_MCP,
            lineItem.FULFILLMENT_ACCEPTED,
            lineItem.FULFILLMENT_ERROR,
            lineItem.FULFILLMENT_REJECTED,
            lineItem.PRINTING_IN_PROGRESS,
            lineItem.DOC_FEEDBACK_REQUESTED,
            lineItem.DOC_REVIEW_APPROVED,
            lineItem.DOC_REVIEW_FAILED,
            lineItem.DOC_REVIEW_IN_PROGRESS,
            lineItem.WAITING_FOR_DOC_REVIEW,
            lineItem.HELD,
          ];
          break;
        case 'shipped':
          lineItemStates = [lineItem.SHIPPED];
          break;
        case 'cancelled':
          lineItemStates = [lineItem.CANCELLED];
          break;
        case 'docReviewInProofing':
          lineItemStates = [lineItem.DOC_REVIEW_PROOFING];
          break;
        default:
          return null;
      }
    }
    // @ts-expect-error - TODO: Fix ignore
    params.set('limit', ordersPerPage);

    if (ordersPerPage && pageIndex) {
      if (pageIndex > 0) {
        const offset = (pageIndex - 1) * ordersPerPage;
        // @ts-expect-error - TODO: Fix ignore
        params.set('offset', offset);
      }
    }

    if (filter) {
      // @ts-expect-error - TODO: Fix ignore
      params.set('lineItemStates', lineItemStates);
      // @ts-expect-error - TODO: Fix ignore
      params.set('orderStates', orderStates);
    }

    if (search) {
      params.set('search', search);
    }

    const { data: { message } } = await this.httpClient.get<GetOrdersResponse>(
      `/order/getMyOrders?${params}`,
      { blockAnonymous: true },
    );
    return Promise.resolve(message);
  };

  getCounters = async () => {
    const { data: { message: counters } } = await this.httpClient.get<CounterResponse>(
      '/order/getMyCounters',
      { blockAnonymous: true },
    );
    return Promise.resolve(counters);
  };

  cancelOrder = async ({
    orderId,
    lineItemIds = [],
    customLineItemIds = [],
  }: { orderId: string, lineItemIds: string[], customLineItemIds: string[] }) => {
    const { data: updatedOrderData } = await this.httpClient.post('/order/cancelEntity', {
      orderId,
      cancelLineItems: lineItemIds,
      cancelCustomLineItems: customLineItemIds,
    });
    return Promise.resolve(updatedOrderData);
  };

  editOrder = async (
    itemId: string,
    orderId: string,
    changes: EditOrderLineItemRequest,
  ) => {
    const { data: { message } } = await this.httpClient.post<OrderEditResponse>(
      `/order/edit/${orderId}/${itemId}`, changes,
    );
    return Promise.resolve(message);
  };

  approveEdit = async (
    itemId: string,
    orderId: string,
    orderEditId: string,
    orderEditVersion: number,
  ) => {
    const { data: { message } } = await this.httpClient.post('/order/edit/apply', {
      lineItemId: itemId,
      orderId,
      orderEditId,
      orderEditVersion,
    });
    return Promise.resolve(message);
  };

  getLatestSelection = async (productKey: string) => {
    const { data: { message } } = await this.httpClient.get('/order/getLatestSelectionUrl', {
      params: {
        productKey,
      },
    });
    return Promise.resolve(message);
  };
}

export default OrderService;
