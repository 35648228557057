import React from 'react';
import { Container } from '@printdeal/ui-library';
import ErrorBoundaryBody from './ErrorBoundaryBody';
import { SentryHelper } from '../../helpers/SentryHelper';
import Logo from '../navigation/header-new/Logo';

interface Props {
  children: React.ReactNode;
  inline?: boolean;
}

interface State {
  error?: Error;
}

const ErrorLayout = () => (
  <Container className="pd-mx-auto">
    <div className="pd-relative pd-grid pd-gap-4 pd-bg-white pd-pt-5">
      <Logo />
      <ErrorBoundaryBody />
    </div>
  </Container>
);

/**
 * https://docs.sentry.io/platforms/javascript/react/
 *
 * "...One important thing to note about the behavior of error boundaries in development mode is that
 * React will rethrow errors they catch." This will result in errors being reported twice to Sentry with
 * the above setup, but this won’t occur in your production build..."
 */
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  componentDidCatch(error:Error, errorInfo:React.ErrorInfo) {
    this.setState({ error });
    import(/* webpackChunkName: "sentry-gatsby" */'@sentry/gatsby')
      .then(({ withScope, captureException }) => {
        withScope((scope) => {
          // @ts-expect-error
          SentryHelper.enhanceScope(scope, 'error', errorInfo);
          captureException(error);
        });
      });
  }

  render() {
    const {
      children,
      inline = false,
    } = this.props;

    const { error } = this.state;

    if (!error) {
      return children;
    }

    if (inline) {
      return <ErrorBoundaryBody />;
    }

    return <ErrorLayout />;
  }
}

export default ErrorBoundary;
