import Api from '../Api';

export type Status = 'approved' | 'rejected' | 'closed';

interface ProposalServicePayload {
  orderId: string;
  lineItemId: string;
  customerAction: Status
}

const proposalStatusMap = {
  AwaitingCustomerConfirmation: 'AwaitingCustomerConfirmation',
  AwaitingForAdjustment: 'AwaitingForAdjustment',
  Rejected: 'Rejected',
  Closed: 'Closed',
} as const;

const iterationStatus = {
  AwaitingConfirmation: 'AwaitingConfirmation',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Closed: 'Closed',
} as const;

export type IterationStatus = keyof typeof iterationStatus;
type ProposalStatus = keyof typeof proposalStatusMap;

interface CheckItResponseObject {
  documentReferenceUrl: string
  renderingInstructionsUrl: string
}

interface CheckItResponseArrayElement {
  Action: {
    modifiedFileUrl: Array<string>
    originalFileUrl: string
  }
  FileName: string
}

export interface Iteration {
  id: string;
  userFile: string[];
  modifiedFile: {
    pdfUrl?: string;
    previewUrls?: string[];
    modifiedUrl? : string;
  };
  checkItResponse: CheckItResponseObject | CheckItResponseArrayElement[];
  status: IterationStatus;
  auxCodes: Array<string>;
  agentComments: string;
  customerComments: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  artworkReviewer: string;
  reviewRequestId: string;
  requestedBy: string;
}

interface CustomerProposal {
  id: string;
  orderId: string;
  lineItemId: string;
  customerId: string;
  lineItemNumber: string;
  status: ProposalStatus;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  iterations: Array<Iteration>;
}

interface ProposalData {
  comments? : string
}

class CustomerProposalService extends Api {
  async updateCustomerAction(proposalPayload: ProposalServicePayload, proposalData? : ProposalData) {
    try {
      const { data } = await this.httpClient.post('/customerAction', proposalData, {
        params: { ...proposalPayload },
      });
      return data;
    } catch (error) {
      // @ts-expect-error
      const { response } = error;
      return response;
    }
  }

  async getProposalDetails(customerId: string, proposalId: string) {
    try {
      const { data } = await this.httpClient.get<{ data: CustomerProposal }>(
        `customers/${customerId}/proposals/${proposalId}`,
      );
      return data.data;
    } catch (error) {
      throw new Error(`Something went wrong while getting proposal details: ${JSON.stringify(error)}`);
    }
  }
}

export default CustomerProposalService;
