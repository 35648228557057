import axios, { AxiosError } from 'axios';
import { addPageViewEntry } from '@printdeal/data-layer-manager';
import { jwtDecode } from 'jwt-decode';
import type { Breadcrumb } from '@sentry/types';
import type { GatsbyBrowser } from 'gatsby';
import supportAutoTranslators from './src/polyfills/support-auto-translators';
import PageElementWrapper from './src/components/PageElementWrapper';
import RootElementWrapper from './src/components/RootElementWrapper';
// @ts-expect-error Need to convert to TS
import { AuthDataLayerHelper } from './src/helpers/dataLayerHelpers/AuthDataLayerHelper';
import { clearBodyScrollLocks } from './src/helpers/scroll-helper';
import { SentryHelper } from './src/helpers/SentryHelper';
import { getFromLocalStorage, LS_KEYS } from './src/helpers/window';
import { dedupeTitle } from './src/helpers/dedupe-title';
import { getVersions } from './src/helpers/version-check/get-versions';
import type { PrintdealAuthTokenPayload } from './src/helpers/auth';

// Import main CSS bundle
import './src/css/global.css';
import './src/style.css';

/**
 * Hacks the DOM Node API to support auto-translators like Google Translate in React.
 * @see https://drukwerkdeal.atlassian.net/browse/SE-108
 * @see https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
supportAutoTranslators();

/**
 * Wrap root and page elements
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 */
export const wrapRootElement = RootElementWrapper;
export const wrapPageElement = PageElementWrapper;

/**
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#disableCorePrefetching
 */
export const disableCorePrefetching: GatsbyBrowser['disableCorePrefetching'] = () => true;

/**
 * Routing
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onPreRouteUpdate
 */
export const onPreRouteUpdate = () => {
  // Clear all body scroll locks
  clearBodyScrollLocks();
};

/** @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate */
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (AuthDataLayerHelper.enableLoginEventOnRouteUpdate()) {
    const customerNumber = getFromLocalStorage(LS_KEYS.CUSTOMER_NUMBER);
    const customerType = getFromLocalStorage(LS_KEYS.CUSTOMER_TYPE);
    const authToken = getFromLocalStorage(LS_KEYS.AUTH_TOKEN);

    if (customerNumber && customerType && authToken) {
      const customerTokenData = jwtDecode<PrintdealAuthTokenPayload>(authToken);

      if (customerTokenData && customerTokenData.account && customerTokenData.account.id) {
        AuthDataLayerHelper.dispatchDataLayerEventOnLogin(
          customerTokenData,
          { customerNumber, customerType },
          true,
        );
      }
    }
  }
  // Search result events are handled in searchResult.js as
  // query changes are not detected by `onRouteUpdate`
  if (location.pathname !== '/nl/result') {
    addPageViewEntry({ path: location.pathname, query: location.search });
  }

  // prevLocation is `null` if we're on the first page
  if (prevLocation) {
    dedupeTitle(location.pathname, prevLocation.pathname);
  }
};

/** @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll */
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps }) => {
  if (routerProps.location.hash) {
    const { hash } = routerProps.location;
    const element = document.querySelector(hash);
    if (element !== null) {
      // Wrapping in setTimeout because Gatsby somehow interferes with our scrollIntoView() call
      // and scrolls to the wrong position.
      // Adding the timeout allows Gatsby to first (invisibly) scroll to the incorrect position
      // after which we can scroll to the correct position.
      setTimeout(() => {
        element.scrollIntoView({ block: 'start' });
      }, 100);
    }
    return false;
  }
  return true;
};

/**
 * Check if a Sentry breadcrumb matches a failed Google Ads URL.
 * @see https://printdeal.sentry.io/issues/4138649686/events/latest for example errors
 * @param breadcrumb
 * @returns {boolean}
 */
const isBlockedGoogleAdBreadcrumb = (breadcrumb: Breadcrumb): boolean => {
  const { category, data, level } = breadcrumb;
  return category === 'fetch'
    && level === 'error'
    && data?.method === 'GET'
    && data?.url === 'https://pagead2.googlesyndication.com/pagead/buyside_topics/set/';
};

/**
 * Check if a Sentry breadcrumb matches a failed XHR request due to not receiving a response
 * @param breadcrumb
 * @returns {boolean}
 */
const isXhrWithoutResponseBreadcrumb = (breadcrumb: Breadcrumb): boolean => {
  const { category, data } = breadcrumb;
  return category === 'xhr' && data?.status_code === 0;
};

/**
 * Called when the Gatsby browser runtime first starts.
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
 */
export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  const sentryEnabled = process.env.GATSBY_ACTIVE_ENV === 'production'
    || process.env.GATSBY_ACTIVE_ENV === 'staging'
    || (process.env.GATSBY_ACTIVE_ENV === 'development' && process.env.GATSBY_SENTRY_DEBUG_MODE === 'true');

  if (sentryEnabled) {
    const [sentryGatsby, sentryBrowserTracing, sentryIntegrations] = await Promise.all([
      import(/* webpackChunkName: "sentry-gatsby" */'@sentry/gatsby'),
      import(/* webpackChunkName: "sentry-tracing" */'@sentry/tracing'),
      import(/* webpackChunkName: "sentry-integrations" */'@sentry/integrations'),
    ]);
    const { Replay } = sentryGatsby;
    const { BrowserTracing } = sentryBrowserTracing;
    const { Dedupe, ExtraErrorData } = sentryIntegrations;

    /**
     * Available Options: https://docs.sentry.io/error-reporting/configuration/?platform=browser
     */
    sentryGatsby.init({
      dsn: process.env.GATSBY_SENTRY_DSN,
      // @ts-expect-error Webpack will replace this with the actual version
      release: __DYNAMIC_RELEASE_STRING__, // eslint-disable-line
      environment: process.env.GATSBY_ACTIVE_ENV,
      debug: process.env.GATSBY_SENTRY_DEBUG_MODE === 'true',
      maxBreadcrumbs: 50,
      autoSessionTracking: true,

      // You can log events to Sentry, even locally in development env, but you need to opt-in for it.
      enabled: sentryEnabled,

      normalizeDepth: 6,
      integrations: [
        new Dedupe(),
        new ExtraErrorData(),

        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        new BrowserTracing(),
        new Replay({
          blockAllMedia: false,
          maskAllInputs: true,
          maskAllText: true,
          blockSelector: '[data-hj-suppress]',
          blockClass: 'data-sentry-block',
          useCompression: true,
          networkDetailAllowUrls: [/\/page-data.*\.json/],
        }),
      ],
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        'CookieControl', // CookieBot related errors
        'webkitPresentationMode',
        'iFrameResizer',
        'ChunkLoadError',
        'Pastease',
      ],
      denyUrls: [
        /accutics\.net/,
        /bing\.com/, // Bing Ads UET
        /cookiebot\.com/,
        /criteo\.com/,
        /doubeclick\.net/,
        /facebook\.net/,
        /google\.com/,
        /google-analytics\.com/,
        /googleadservices\.com/,
        /googlesyndication\.com/,
        /googletagmanager\.com/,
        /hotjar\.com/,
        /hs-analytics\.com/,
        /mopinion\.com/,
        /omappapi\.com/,
        /opmnstr\.com/,
        /segment\.com/,
        /snap\.licdn\.com/,
        /zdassets\.com/,
        /zendesk\.com/,
        /zopim\.com/,
      ],
      beforeSend: async (event, hint) => {
        const extraTags: {
          currentVersion?: string;
          latestVersion?: string;
          versionLoadFailure?: boolean;
          httpStatusCode?: number;
        } = {};

        if (event.breadcrumbs?.some(isBlockedGoogleAdBreadcrumb)) {
          // Error is caused by a Google Ads endpoint getting blocked by an ad blocker. Ignore this error
          return null;
        }

        // @ts-expect-error This property _might_ be added to the hint by Axios if it's an Axios error
        if (hint.originalException?.isAxiosError) {
          const originalException = hint.originalException as AxiosError;

          if (!originalException?.response) {
            // This error is caused by a request that somehow did not result in a response. In many cases, this means
            // that something went wrong in the user's browser. To prevent spamming Sentry, skip these errors.
            return null;
          }
        }

        if (event.breadcrumbs?.some(isXhrWithoutResponseBreadcrumb)) {
          // Same as above, a request failed due to not receiving a response, but it was not done by axios. This is
          // usually caused by a browser issue while fetching page data. These errors can be ignored
          return null;
        }

        if (!['production', 'staging', 'development'].includes(process.env.GATSBY_ACTIVE_ENV as string)) {
          // Skip any environments except production, staging and development
          return null;
        }

        if (event.level === 'debug' && process.env.GATSBY_SENTRY_DEBUG_MODE !== 'true') {
          // Skip debug messages unless specifically configured to also log those
          return null;
        }

        // Add extra information about which build the user is on and which is the latest build
        try {
          const { current, latest } = await getVersions();

          /*
           * For page-data load errors, if it is caused by a version mismatch, reload the page instead of sending an
           * error.
           */
          const pageDataRegex = /We couldn't load "\/page-data\/sq\/d\/\d+\.json"/;
          const [{ value: message }] = event.exception?.values || [];
          if (current !== latest && message && pageDataRegex.test(message)) {
            window.location.reload();
            return null;
          }

          extraTags.currentVersion = current;
          extraTags.latestVersion = latest;
        } catch {
          extraTags.versionLoadFailure = true;
        }

        // Add the HTTP response code as a tag
        // @ts-expect-error This property _might_ be added to the hint by Axios if it's an Axios error
        if ('isAxiosError' in hint.originalException) {
          const originalException = hint.originalException as AxiosError;
          extraTags.httpStatusCode = originalException.response?.status;
        }

        return {
          ...event,
          tags: { ...event.tags, ...extraTags },
        };
      },
      beforeBreadcrumb: (breadcrumb: Breadcrumb) => {
        // All related to Hot Module Reloading locally, so no need to have it.
        if (breadcrumb.category === 'xhr' && breadcrumb.data && /socket.io\/\?EIO/.test(breadcrumb.data.url)) {
          return null;
        }

        if (breadcrumb.category === 'console' && breadcrumb.message && /\[HMR\]/.test(breadcrumb.message)) {
          return null;
        }

        return breadcrumb;
      },

      // Stacktrace is always attached during errors.
      // If you want to see it also for debug, warning and info messages, set the GATSBY_SENTRY_DEBUG_MODE to 'true'
      attachStacktrace: process.env.GATSBY_SENTRY_DEBUG_MODE === 'true',

      // Set tracesSampleRate to 1.0 to be 100%.
      tracesSampleRate: 0.02,

      // This sets the sample rate to be 0%. You may want this to be 100% while
      // in development for testing purposes.
      replaysSessionSampleRate: 0,

      // All sessions with errors will be sent to Sentry Replay.
      replaysOnErrorSampleRate: 1.0,
    });
  }

  if (process.env.GATSBY_CHECK_CUSTOM_DOMAIN === 'true') {
    try {
      // Check whether the user can access our custom domains
      await axios.get(`${process.env.GATSBY_AUTH_ENDPOINT_CUSTOM}/status`);
    } catch (error) {
      const { response } = error as AxiosError;

      // Log error to Sentry
      SentryHelper.exception({
        e: 'Unable to reach custom authentication domain',
        tags: {
          fileLocation: 'gastby-browser',
          featureFunction: 'testCustomDomain',
        },
        fingerPrint: ['gastby-browser-test-custom-domain'],
        extra: {
          error,
          statusCode: response?.status,
        },
      });
    }
  }
};
