import { useLocation } from '@gatsbyjs/reach-router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { VatModeProvider } from '@printdeal/product-selector';
import { getLocaleCodeFromLocale } from '../helpers/translations';
import useTranslations from '../hooks/useTranslations';
import { ServiceFactory } from '../services/ServiceFactory';
import { actions as authActions } from '../store/slices/authSlice';
import { IPageContext, Locale } from '../types/gatsby';
import ErrorBoundary from './error-handling/ErrorBoundary';
import IntlProvider from './IntlProvider';
import OrganizationStructuredData from './Organisation';
import { FeatureManagementProvider } from './feature-management';

// @design-stack and @design-experiences rely on the runtime being present
import 'regenerator-runtime/runtime';

import Chat from './widgets/chat/Chat';

import { LandingPageScript } from './webgain/LandingPageScript';

interface Props {
  pageContext: IPageContext;
  children: React.ReactNode;
  authenticate: () => void;
}

const PageElementWrapper = ({ pageContext, children, authenticate }: Props) => {
  // Authenticate on every location change
  const location = useLocation();
  useEffect(() => {
    authenticate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { portalConfiguration } = useStaticQuery<{ portalConfiguration: { locales: string[], defaultLocale: Locale } }>(
    graphql`
    {
      portalConfiguration {
        defaultLocale
        locales
      }
    }
  `,
  );

  let localeFromPage = pageContext.locale || portalConfiguration.defaultLocale;

  useEffect(() => {
    const wrapper = document.getElementById('CookieConsentWrapper');
    const script = document.createElement('script');

    function onAccept(event: Event) {
      // @ts-expect-error
      const { Cookiebot } = event.target;
      Cookiebot.runScripts.apply(Cookiebot);
    }

    if (wrapper) {
      script.id = 'CookieConsent';
      script.src = `https://consent.cookiebot.com/uc.js?cbid=${process.env.GATSBY_COOKIEBOT_CBID}`;
      script.async = true;
      script.setAttribute('data-culture', getLocaleCodeFromLocale(localeFromPage));

      wrapper.appendChild(script);

      window.addEventListener('CookiebotOnAccept', onAccept);
    }

    const itpService = ServiceFactory.createItpService();
    itpService.getItp(localeFromPage);

    return () => {
      if (!wrapper) return;

      wrapper.removeChild(script);
      window.removeEventListener('CookiebotOnAccept', onAccept);
    };
  }, [localeFromPage]);

  // In case of a 404, the locale isn't set in pageContext.
  // We'll try to see if the first section of the pathname matches
  // any of our configured locales.
  //
  // Example 404 url with locale: `/fr/asdf`
  // -------------------------------^^
  //
  // Example 404 without locale: `/asdf`
  //
  // If so: we'll set that as the locale.
  // If not: use the default locale.
  if (!pageContext.locale) {
    // The firstSlug *possibly* contains a locale
    const [, firstSlug] = location.pathname.split('/');

    // => ['nl-BE', 'fr-BE'] -> ['nl', 'fr']
    const localeSlugs = portalConfiguration.locales.map((localeCode) => localeCode.slice(0, 2));
    const localeIndex = localeSlugs.indexOf(firstSlug);
    const supportsLocale = localeSlugs.includes(firstSlug);

    if (supportsLocale) {
      localeFromPage = portalConfiguration.locales[localeIndex] as Locale;
    }
  }
  const messages = useTranslations(localeFromPage);

  // Provide intl at page level, because we need the locale from page context
  return (
    <>
      <IntlProvider localeFromPage={localeFromPage} messages={messages}>
        <FeatureManagementProvider>
          <VatModeProvider>
            <ErrorBoundary>
              <Chat>
                {children}
                <OrganizationStructuredData />
              </Chat>
            </ErrorBoundary>
          </VatModeProvider>
        </FeatureManagementProvider>
      </IntlProvider>
      <ErrorBoundary inline>
        <div id="CookieConsentWrapper" />
      </ErrorBoundary>
      <LandingPageScript />
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
    </>
  );
};

const mapActionsToProps = {
  authenticate: authActions.authenticate,
};

const ConnectedPageElementWrapper = connect(null, mapActionsToProps)(PageElementWrapper);

// This wrapper is needed because of the Gatsby browser API
// eslint-disable-next-line react/function-component-definition, func-names
export default function ({ element, props }: { element: React.ReactNode, props: Props }) {
  return <ConnectedPageElementWrapper {...props}>{element}</ConnectedPageElementWrapper>;
}
